.badge {
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background-color: #7f56d9;
  position: absolute;
  top: 2px;
  left: 3px;
}

.dropdown {
  box-sizing: border-box;
  max-height: calc(100vh - var(--mantine-header-height));
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.header {
  flex-shrink: 0;
}

.content {
  flex-shrink: 1;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin-right: -16px;
  padding-right: 16px;
}

.removeBtn {
  &:hover {
    color: red;
  }
}

.content::-webkit-scrollbar-track {
  border-radius: 12px;
}

.content::-webkit-scrollbar {
  width: 8px;
}

.content::-webkit-scrollbar-thumb {
  border-radius: 12px;
  background-color: rgba(0, 0, 0, 0.4);
  transition: background-color 150ms ease;

  &:hover {
    background-color: rgba(0, 0, 0, 0.5);
  }
}
