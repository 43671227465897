.navLink {
  text-decoration: none;
  color: black;
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    color: #7f56d9;
  }
}

.activeNavLink {
  color: #7f56d9;
}

.innerLink {
  text-decoration: none;
  color: black;
  font-weight: 400;
  font-size: 16px;
  cursor: pointer;

  svg {
    color: #bfcedb;
  }

  &:hover {
    color: #7f56d9;

    svg {
      color: #7f56d9;
    }
  }
}

.activeInnerLink {
  color: #7f56d9;

  svg {
    color: #7f56d9;
  }
}
