.LayoutAuth {
  font-family: var(--font-secondary);
}

.formCol {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 16px;
  height: 100%;
  background-color: #ffffff;
}

.headerForm {
  position: absolute;
  top: 40px;
  right: 54px;

  @media (max-width: 767px) {
    right: 10px;
  }
}

@media (max-width: 1024px) {
  .authImage {
    display: none;
  }
}
