//common

.Button {
  cursor: pointer;
  color: inherit;
  font-family: inherit;
}

.buttonWithIcon {
  display: flex;
  align-items: center;
  gap: 15px;
}

.iconButton {
  width: 1.3em;
  height: 1.3em;

  svg {
    width: 100%;
    height: 100%;
  }
}

// themes
.default {
  background-color: var(--secondary-color);
  color: var(--white-color);
  border: none;
  border-radius: var(--border-radius-size-primary);
  padding: 20px;
}

.clear {
  padding: 0;
  background: none;
  border: none;
  outline: none;

  &:hover {
    color: var(--secondary-color);
  }
}

// configure icon

.iconClear {
  &:hover {
    svg {
      stroke: var(--secondary-color);
    }
  }
}

.iconDefault {
  color: var(--white-color);
}
