.SelectLanguage {
  position: relative;
}

.chevronIcon {
  transition: rotate 0.15s linear;
}

.chevronIconActive {
  rotate: 180deg;
}

.menuDropdown {
  --padding-dropdown: 15px;
  position: absolute;
  top: calc(-1px - var(--padding-dropdown));
  right: calc(-2px - var(--padding-dropdown));
  width: 200px;
  background-color: var(--secondary-bg-color);
  border-radius: var(--border-radius-size-primary);
  box-shadow: var(--box-shadow-value);
  padding: var(--padding-dropdown);
  flex-direction: column;
  gap: 20px;
  border: 1px solid var(--border-color);
  z-index: 1000;
}

.active {
  display: flex;
  animation: shows 0.15s linear;
}

.notActive {
  display: none;
  animation: hiddenF 0.15s linear;
}

.firstItemDropdown {
  display: flex;
  font-weight: 700;
  //background-color: var(--fourth-color);
  align-items: center;
  justify-content: space-between;
}

@keyframes shows {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes hiddenF {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.menuDropdownItem {
  cursor: pointer;

  &:first-of-type {
    &:hover {
      color: inherit;
    }
  }

  &:hover {
    color: var(--secondary-color);
  }
}
