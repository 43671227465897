.Logo {
  margin-bottom: 5px;
}

.logo {
  font-weight: 500;
  font-size: 30px;
  line-height: 1;
  white-space: nowrap;
  text-decoration: none;
  color: rgb(30, 30, 30);
  font-family: "GT Eesti Pro Display", Serif;

  &:hover {
    cursor: pointer;
  }
}
