$sizes: (
  "xs": 375px,
  "sm": 767px,
  "md": 1024px,
  "lg": 1360px,
  "xl": 1580px,
);

@mixin breakpoint($minmax, $media) {
  @each $size, $resolution in $sizes {
    @if $media == $size {
      @media only screen and (#{$minmax}-width: $resolution) {
        @content;
      }
    }
  }
}
