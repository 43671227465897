.NavbarDrawer {
  font-family: var(--font-secondary);
  font-size: var(--font-size-primary);
  font-weight: 700;
  display: flex;

  flex-direction: column;
  height: 100%;
  width: 100%;
  position: absolute;
  justify-content: space-between;
  overflow-y: auto;
  padding-right: 8px;

  &::-webkit-scrollbar-track {
    border-radius: 12px;
  }

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 12px;
    background-color: var(--color-icons-passive);
    transition: background-color 150ms ease;

    &:hover {
      background-color: var(--gray-color);
    }
  }
}

.headerDrawer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-family: var(--font-secondary);
}

.headerDrawerRight {
  display: flex;
  align-items: center;

  & > div {
    margin-right: 15px;
  }

  & > button {
    margin-right: 15px;
  }
}

.DrawerBody {
  position: relative;
}

.logoutBtn {
  padding-bottom: 10px;
}
