@import "/src/styles/variables";

:root {
  --white: #ffffff;
}

.title2 {
  font-size: 80px;
  font-weight: 700;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px var(--white);
  line-height: 1;
  text-transform: uppercase;

  @include breakpoint("max", "lg") {
    font-size: 70px;
  }
}

.title {
  line-height: 1;
  font-size: 100px;
  font-weight: 700;
  color: #ffffff !important;
  text-transform: uppercase;
  -webkit-text-fill-color: #ffffff;
  -webkit-text-stroke: 0 #ffffff;

  @include breakpoint("max", "xl") {
    font-size: 80px;
  }

  @include breakpoint("max", "lg") {
    font-size: 70px;
  }
}

.textBox {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: end;
}
