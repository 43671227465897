.languages {
  & button {
    color: #000;
  }

  & ul {
    top: auto;
    bottom: auto;
  }
}

.sessions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .title {
    font-weight: bold;
    color: #495057;
  }
}
