.badgeBtn {
  margin-left: auto;
  margin-right: -12px;
}

.badge {
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background-color: #7f56d9;
}

.removeBtn {
  position: relative;
  z-index: 1;

  &:hover {
    color: red;
  }
}
