.regions {
  width: 100%;
  border-radius: 8px;

  & button {
    padding: 8px 10px;
    color: #000;
    height: 42px;
    border-radius: 8px;
  }

  & ul {
    top: auto;
    bottom: auto;
  }
}

.error {
  button {
    border-color: #fa5252;
  }
}
