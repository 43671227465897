.menuFlags {
  width: 175px;
  padding-bottom: 0;

  & button {
    background-color: #fff;
  }

  & ul {
    top: auto;
    bottom: 100%;
    border-radius: 4px;
    border: 1px solid #ced4da;

    & span {
      font-size: 14px;
    }

    overflow-y: auto;
    overflow-x: hidden;

    /* Works on Firefox */
    scrollbar-color: #ced4da transparent;
    scrollbar-width: thin;
    scrollbar-gutter: 5px;

    /* Works on Chrome, Edge, and Safari */
    &::-webkit-scrollbar {
      width: 4px;
      border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #ced4da;
      border-radius: 5px;
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
    }
  }
}

.menuFlagsButton {
  border-radius: 4px;
  border: 1px solid #ced4da;
  background-color: #fff;
  padding: 3px 10px;

  & span {
    font-size: 14px;
  }
}

.label {
  display: block;
  margin-top: 15px;
}
