.AccordionMenu {
  font-size: var(--font-size-primary);
}

.AccordionMenuLabel {
  font-weight: 700;
}

.AccordionMenuChevron {
  position: absolute;
  margin-right: 10px;
  width: 20px;
  min-width: 20px;
  transform: rotate(-90deg);
  cursor: pointer;

  &[data-rotate] {
    transform: rotate(0);
  }

  &:empty {
    display: none;
  }

  svg {
    width: 25px;
    height: 25px;
  }
}

.AccordionMenuControl {
  //padding: 15px 10px;
  cursor: default;
  padding: 0;

  &:hover {
    background-color: transparent;
  }
}

.AccordionMenuPanel {
  font-weight: 500;
}

.AccordionMenuItem {
  border-bottom: 1px solid var(--border-color);

  &:last-of-type {
    border-bottom: 0;
  }
}

.links {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.accordionItem {
  padding: 19px 10px;

  a {
    padding: 0;
  }
}

.accordionItemActive {
  background-color: var(--fourth-color);
  border-radius: var(--border-radius-size-primary);
}

.itemsInAccordion {
  padding: 8px 5px;
  line-height: 1;
}
