.navLink {
  text-decoration: none;
  color: var(--primary-color);
  cursor: default;
  display: inline-block;

  span {
    cursor: pointer;
    display: flex;
    gap: 5px;
    align-items: center;

    svg {
      color: var(--color-icons-passive);
    }

    &:hover {
      color: var(--secondary-color);

      svg {
        color: var(--secondary-color);
      }
    }
  }
}

.navLinkIcon {
  margin-right: 5px;
  width: 1.3em;
  height: 1.3em;

  svg {
    width: 100%;
    height: 100%;
  }
}

.activeNavLink {
  color: var(--secondary-color);
  font-weight: 700;

  span {
    svg {
      color: var(--secondary-color);
    }
  }
}
