@import "reset";
@import "variables/global";
@import "themes/normal";
@import "themes/dark";

.app {
  font-family: var(--font), serif;
}

body {
  font-family: var(--font), serif;
}
