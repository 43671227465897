.Header {
  width: 100%;
  height: var(--navbar-height);
  background: var(--secondary-bg-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  font-family: var(--font-secondary);
  font-weight: 500;
  font-size: var(--font-size-primary);
}

.headerRight {
  display: flex;
  align-items: center;
  gap: 20px;
  font-size: var(--font-size-secondary);
}
